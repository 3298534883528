@import 'src/styles/media';

.Wrapper {
  margin-top: 103px;

  @include media-sm {
    margin-top: 0;
    width: 100%;
  }
}

.logo {
  @include media-sm {
    display: none;
  }
}

.Wrapper.LoaderWrapper {
  margin-top: 300px;
}

.paper {
  margin-top: 25px;
  max-width: 580px;
  width: 100%;
  padding: 50px 60px 40px;
  border-radius: 8px;
  box-shadow: 10px 10px 40px 0 #d8dfea;
  background-color: #fff;

  @include media-sm {
    padding: 20px 0;
    box-shadow: none;
  }
}

.title {
  font-family: inherit;
  font-size: 36px;
  font-weight: 700;
  color: var(--primary-text-color);
  margin: 0 0 40px;

  @include media-sm {
    font-size: 16px;
    font-weight: 500;
  }
}

.select {
  overflow: auto;
  max-height: 400px;
  min-height: 80px;
}

.formActions {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helpLink {
  display: block;
  width: 25px;
  height: 25px;
}

.helpLink:hover svg {
  fill: var(--primary-button-color);
}

.helpLink svg {
  transition: 0.15s fill;
  fill: #757295;
  width: 100%;
  height: 100%;
}

.submit {
  min-width: 220px;
  margin-left: 20px;
}

.itemInfo {
  border-bottom: solid 0.5px rgba(114, 124, 142, 0.15);
}
