@import 'src/styles/media';

.Wrapper {
  margin-top: 117px;
  min-height: calc(100vh - 117px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-sm {
    min-height: 100vh;
    margin-top: 0;
    padding: 40px 0;
    justify-content: center;
  }
}

.logo {
  zoom: 1.3; // TODO: think to fox
  margin-bottom: 10px;

  @include media-sm {
    zoom: 1;
  }
}

.paper {
  margin: 20px auto;
  width: 580px;
  box-shadow: 10px 10px 40px 0 #d8dfea;

  @include media-sm {
    box-shadow: none;
    width: auto;
  }
}

.container {
  max-width: 450px;
  margin: 0 auto;
  padding: 60px 0;

  @include media-sm {
    padding: 0;
  }
}
.container .title {
  font-size: 36px;
  font-weight: 700;
  color: var(--primary-text-color);
  padding: 0 30px;
  line-height: 1.3;

  @include media-sm {
    box-shadow: none;
    width: auto;
    font-size: 32px;
    padding: 0;
  }
}
.container .description {
  margin: 20px 0 30px;
  font-size: 15px;
  color: var(--primary-text-color);
  font-weight: 400;
}
.container .description a {
  color: #5f48ed;
}
.container .buttonsWrapper {
  margin-bottom: 40px;
}

.noticeWrapper .notice {
  font-size: 11px;
  color: var(--primary-text-color);
}
.noticeWrapper .notice a {
  color: var(--primary-text-link-color);
}

.footerWrapper {
  max-width: 450px;
  width: 100%;
  margin: 0 auto 50px;

  @include media-sm {
    display: none;
  }
}
.footerWrapper .divider {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid var(--primary-border-color);
}
.footerWrapper .footerDescription {
  font-size: 11px;
  color: var(--primary-text-color);
}

.footerWrapper .footerDescription a {
  color: var(--primary-text-link-color);
}
