.dropdown {
  transform: translate(10px) !important;
}
.margin {
  margin-bottom: 30px;
}
.popper {
  margin-top: 10px;
}
.label {
  font-size: 16px;
  font-weight: 500;
  color: #001847;
  transform: translate(0, 0) scale(1);
  position: relative;
  text-align: left;
}
.autocompleteWrapper {
  height: 40px;
  background-color: #fff;
}
.autocompleteInput {
  height: 36px;
  margin: 10px;
  width: 100%;
  max-width: 230px;
  & input {
    border-radius: 5px;

    padding: 10px;
    font-size: 11px;
    background-color: #f5f5f8;
  }
}
.hiddenInput {
  margin-top: 20;
}
.buttonPhoneCode {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-left: 10px;
}
.flagWrapper {
  border-radius: 50%;
  display: block;
  max-width: 20px;
  max-height: 20px;
  overflow: hidden;
  /* border: 1px solid; */
  position: relative;
  .flag {
    font-size: 40px;
    line-height: 21px;
    /* padding: 10px 0 0; */
    padding-left: -10px;
    position: relative;
    top: -50%;
    left: -50%;
    transform: translate(50%, 50%);
  }
}
.phoneNumberLabel {
  font-size: 16px;
  line-height: 24px;
  color: #001847;
  margin: 0 0 7px;
  font-weight: 500;
  text-align: left;
}
.phoneInput {
  input {
    &::placeholder {
      font-size: 13px;
      color: #000;
    }
  }
}
.phoneCode {
  margin: 0 10px;
}
.popupAutocompleteWrapper {
  background: #fff;
  height: 320px;
  width: 250px;
  left: -11px !important;
  top: 6px !important;
  box-shadow: 10px 10px 40px 0 #d8dfea;
  border-radius: 12px;
}
.phoneDivider {
  margin: 8px 10px;
}
.listPhoneCountries {
  width: 100% !important;
  margin-top: 0 !important;
  .listPhoneCountriesPaper {
    margin-top: -10px;
    box-shadow: none !important;
  }
}
.listbox {
  max-height: 260px !important;
}
.optionCountryRow {
  display: flex;
  align-items: center;
  .flagWrapper {
    margin-right: 10px;
  }
}
.submit {
  height: 40px;
}
.inputCompanySize {
  text-align: left;
}
.phoneCodeSelect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
