.IndustryWrapper {
  margin-top: 75px;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepCount {
  font-size: 15px;
  color: var(--secondary-text-color);
}
.stepMainTitle {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-text-color);
  margin-top: 30px;
}

.stepDescription {
  font-size: 15px;
}
.autocomplete :global(.MuiAutocomplete-endAdornment) {
  margin-right: 10px;
}
.autocomplete :global(.MuiAutocomplete-popupIndicatorOpen) {
  display: none;
}
.autocomplete :global(.MuiAutocomplete-popupIndicator) svg {
  color: var(--primary-text-color);
}
.autocomplete :global(.MuiAutocomplete-clearIndicatorDirty) {
  visibility: visible !important;
}
.autocomplete :global(.MuiAutocomplete-clearIndicatorDirty) svg {
  width: 17px;
  color: var(--primary-text-color);
}
.autocomplete
  :global(.MuiAutocomplete-clearIndicatorDirty)
  + :global(.MuiAutocomplete-popupIndicator) {
  display: none;
}
.autocomplete + :global(.MuiAutocomplete-popper) {
  position: absolute;
  max-height: 230px;
  margin-top: 10px;
}
/* .autocomplete + :global(.MuiAutocomplete-popper) :global(.MuiAutocomplete-paper) {
  height: 230px;
} */
.stepTitle {
  font-weight: 500;
  font-size: 21px;
}
.select {
  height: 40px;
  margin-top: 7px !important;
}
.form {
  max-width: 450px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 40px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit {
  border-radius: 20px;
  margin-top: 20px;
  background-color: var(--primary-button-color);
  text-transform: capitalize;
  color: #fff !important;
  box-shadow: none;
}
.halfInputWrapper {
  width: 50%;
}
.halfInputWrapper:first-of-type {
  margin-right: 14px;
}
.submit:hover {
  background-color: var(--primary-button-color);
}
.submit:disabled {
  opacity: 0.1;
  border-radius: 20px;
  background-color: var(--primary-button-color);
  font-size: 15px;
}
.label {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
  transform: translate(0, 0) scale(1);
  position: relative;
  text-align: left;
}
.label:global(.Mui-focused) {
  color: var(--primary-text-color);
}
.inputBase {
  padding-left: 16px;
  height: 40px;
  margin-top: 7px;
  border-radius: 6px;
  border: 1px solid var(--primary-border-blur-color);
}
.inputBase:global(.Mui-focused) {
  border: 1px solid var(--primary-text-link-color);
}
.hiddenInput {
  margin-top: 20px;
}
.autocompleteWrapper {
  height: 40px;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  border: 1px solid var(--primary-border-blur-color);
  border-radius: 6px;
}
.labelRoot {
  color: var(--primary-text-color);
  font-size: 16px;
  position: relative;
  text-align: left;
  margin-bottom: -7px;
  font-weight: 500;
}
.shrink {
  transform: none;
}
.labelRoot:global(.Mui-focused),
.labelRoot:global(.Mui-error) {
  color: var(--primary-text-color);
}
.autocompleteInputField {
  padding: 0 0 0 16px !important;
  margin-top: 8px;
  border-radius: 6px;
  border: 1px solid var(--primary-border-blur-color);
  font-size: 15px;
  min-height: 40px;
  transition: 0.3s;
}
.autocompleteInputField:global(.Mui-focused) {
  border: 1px solid var(--primary-text-link-color);
}
.autocompleteInputField:global(.Mui-error) {
  border: 1px solid var(--primary-text-error-color);
}
